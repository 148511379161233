import LiveEventModule from '@/store/model/LiveEventModule'
import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import moment from 'moment'
import Api from './Api'
import SelectOption from './interface/SelectOption'

export default class LiveEvent {
  public id: string = '';

  public league: string = '';

  public name: string = '';

  public network_ids: string[] = [];

  public created_at: string = '';

  public updated_at: string = '';

  constructor(data: any) {
    this.id = data.id
    this.league = data.league
    this.name = data.name
    this.network_ids = data.network_ids
    this.created_at = data.created_at
    this.updated_at = data.updated_at
  }

  public static toObject(data: any, cache: boolean = true): LiveEvent {
    const event = new LiveEvent(data)

    //  Cache Object
    if (cache) LiveEvent.module.update(event)

    return event
  }

  public static toObjectList(data: any, cache: boolean = true): LiveEvent[] {
    const events = new Array<LiveEvent>()
    data.forEach((value: any) => {
      const event = LiveEvent.toObject(value, false)
      events.push(event)
    })

    //  Cache Object
    if (cache) LiveEvent.module.update(events)

    return events
  }

  /// State Management
  public static get module(): LiveEventModule {
    if (!store.hasModule('live_event')) {
      store.registerModule('live_event', LiveEventModule)
    }

    return getModule(LiveEventModule)
  }

  public static find(id: string): LiveEvent | null {
    const o = LiveEvent.module.data.find(event => event.id === id)
    return o instanceof LiveEvent ? o : null
  }

  public static getAllByLeague(
    league: string,
    network_ids: string[],
  ): LiveEvent[] {
    const events = LiveEvent.module.data
    const list = events.filter(
      (event: LiveEvent) => event.league === league
        && (network_ids.length === 0
          || network_ids.some(n => event.network_ids.includes(n))),
    )

    return list
  }

  public static getOptionsByLeague(
    league: string,
    network_ids: string[],
  ): SelectOption[] {
    return LiveEvent.getAllByLeague(league, network_ids)
      .sort((a, b) => moment(a.created_at).diff(moment(b.created_at)))
      .map((event: LiveEvent) => new SelectOption(
        `[${moment(event.created_at).format('MM/DD/YY h:mm a')}] ${
          event.name
        }`,
        event.id,
      ))
  }

  public static getNetworkOptions(league: string): SelectOption[] {
    const network_ids: string[] = []
    LiveEvent.getAllByLeague(league, []).forEach((event: LiveEvent) => {
      event.network_ids.forEach((network: string) => {
        if (!network_ids.includes(network)) {
          network_ids.push(network)
        }
      })
    })

    return network_ids
      .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
      .map((network: string) => new SelectOption(network, network))
  }

  public static fetchAllValidEvents(): Promise<LiveEvent[]> {
    const api = new Api()
    return api.get('live_events/valid').then(response => LiveEvent.toObjectList(response.data.result.live_events))
  }
}
