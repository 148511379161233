/** @format */

import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import AdunitScheduler from '@/models/AdunitScheduler'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'adunit_scheduler',
})
export default class AdunitSchedulerModule extends VuexModule {
  public data: AdunitScheduler[] = [];

  public options: SelectOption[] = [];

  public synchronized: boolean = false;

  public get api() {
    return new Api(false)
  }

  @Mutation
  public setSynchronized(status: boolean) {
    this.synchronized = status
  }

  @Mutation
  public update(schedulers: AdunitScheduler | AdunitScheduler[]) {
    let data: Array<AdunitScheduler> = []
    if (schedulers instanceof AdunitScheduler) {
      data.push(schedulers)
    } else {
      data = schedulers
    }

    let result = this.data

    data.forEach((adunit_scheduler: AdunitScheduler) => {
      let found: boolean = false
      result = result.map((obj: AdunitScheduler) => {
        if (obj.id == adunit_scheduler.id) {
          found = true
          return adunit_scheduler
        }
        return obj
      })

      if (!found) {
        result.push(adunit_scheduler)
      }
    })

    this.data = result
  }

  @Mutation
  public delete(schedulers: AdunitScheduler | AdunitScheduler[]) {
    let data: Array<AdunitScheduler> = []
    if (schedulers instanceof AdunitScheduler) {
      data.push(schedulers)
    } else {
      data = schedulers
    }

    let result = this.data

    data.forEach((adunit_scheduler: AdunitScheduler) => {
      result = result.filter((obj: AdunitScheduler) => obj.id != adunit_scheduler.id)
    })

    this.data = result
  }

  @Action
  public async find(id: string): Promise<AdunitScheduler | null> {
    return new Promise(resolve => {
      const o = this.data.find(adunit_scheduler => adunit_scheduler.id === id)

      if (o instanceof AdunitScheduler) {
        resolve(o)
      } else {
        return this.api
          .get(`adunit_scheduler/${id}`)
          .then(response => {
            // Parse & cache data
            const data = AdunitScheduler.toObject(
              response.data.result.adunit_scheduler,
            )

            if (data instanceof AdunitScheduler) {
              resolve(data)
            }
          })
          .catch(() => {
            resolve(null)
          })
      }
    })
  }

  @Action
  public async paginate(options: PaginateOptions) {
    return this.api
      .get('adunit_schedulers/paginate', options)
      .then(response => {
        // Parse & cache data
        const data = AdunitScheduler.toObjectList(
          response.data.result.adunit_schedulers,
        )

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  @Action
  public async searchOptions(query: any) {
    return this.api
      .get('adunit_schedulers/search/option', query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }
}
