/** @format */

import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Inventory from '@/models/Inventory'
import PaginateOptions from '@/models/interface/PaginateOptions'
import Api from '@/models/Api'

@Module({
  dynamic: true,
  store,
  namespaced: true,
  name: 'inventory',
})
export default class InventoryModule extends VuexModule {
  public type_options = [
    new SelectOption('SportStream', 'sportstream'),
    new SelectOption('SportStreamTV', 'sportstream_tv'),
    new SelectOption('SportStreamLive', 'sportstream_live'),
    new SelectOption('NewStream', 'newstream'),
  ]

  public device_options = [
    new SelectOption('Connected TV', 'ctv'),
    new SelectOption('Desktop', 'desktop'),
    new SelectOption('Mobile', 'mobile'),
  ]

  public device_name_options = [
    new SelectOption('Connected TV', 'Connected TV'),
    new SelectOption('Desktop', 'Desktop'),
    new SelectOption('Mobile', 'Mobile'),
  ]

  public publisher_options = [
    new SelectOption('MLB.TV', 'mlb'),
    new SelectOption('NHL.TV', 'nhl'),
    new SelectOption('NBA.TV', 'nba'),
  ]

  public live_options = [new SelectOption('Yes', true), new SelectOption('No', false)]

  public data: Inventory[] = []

  public all_data: Inventory[] = []

  public tree: Array<any> = []

  public options: SelectOption[] = []

  public synchronized: boolean = false

  public get api() {
    return new Api(false)
  }

  @Mutation
  public setSynchronized(status: boolean) {
    this.synchronized = status
  }

  @Mutation
  public setTree(tree: any) {
    this.tree = tree
  }

  @Mutation
  public setRawInventory(data: any) {
    this.all_data = data
  }

  @Action
  public async getTree(adserver: string = ''): Promise<any> {
    return new Promise(resolve => {
      // if (this.tree.length > 0) {
      //   resolve(this.tree)
      // } else {
      let payload: any = {}
      if (adserver) {
        payload = { adserver }
      }
      this.api
        .get('inventories', payload)
        .then(response => {
          let data = Inventory.toObjectList(response.data.result.inventories, false)
          this.setRawInventory(data)
          const tree = Inventory.buildTree(data, null)
          this.setTree(tree)
          resolve(tree)
        })
        .catch(() => {
          resolve(null)
        })
      // }
    })
  }

  @Action
  public async paginate(options: PaginateOptions) {
    return this.api
      .get('inventories/paginate', options)
      .then(response => {
        const o = Inventory.toObjectList(response.data.result.results)

        return {
          records: response.data.result.records,
          data: o,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  @Action
  public async find(id: string): Promise<Inventory | null> {
    return new Promise(resolve => {
      const o = this.data.find(inventory => inventory.id === id)

      if (o instanceof Inventory) {
        resolve(o)
      } else {
        return this.api
          .get(`inventory/${id}`)
          .then(response => {
            // Parse & cache data
            const data = Inventory.toObject(response.data.result.inventory)

            if (data instanceof Inventory) {
              resolve(data)
            }
          })
          .catch(() => {
            resolve(null)
          })
      }
    })
  }

  @Mutation
  public update(inventories: Inventory | Inventory[]) {
    let data: Array<Inventory> = []
    if (inventories instanceof Inventory) {
      data.push(inventories)
    } else {
      data = inventories
    }

    let result = this.data

    data.forEach((inventory: Inventory) => {
      let found: boolean = false
      result = result.map((obj: Inventory) => {
        if (obj.id == inventory.id) {
          found = true
          return inventory
        }
        return obj
      })

      if (!found) {
        result.push(inventory)
      }
    })

    this.data = result
  }
}
