export default class ColorManager {
  public static colors: string[] = [
    '#547fff',
    '#7f3ddb',
    // "#9964e3",
    '#e54e9a',
    '#f55d5d',
    '#ff7d47',
    '#ffc247',
    '#41b883',
    // "#3abf94",
    '#17a2b8',
    '#e43d30',
  ]

  public static LightenDarkenColor(col: string, amt: number): string {
    let usePound = false
    if (col[0] == '#') {
      col = col.slice(1)
      usePound = true
    }

    const num = parseInt(col, 16)

    let r = (num >> 16) + amt

    if (r > 255) r = 255
    else if (r < 0) r = 0

    let b = ((num >> 8) & 0x00ff) + amt

    if (b > 255) b = 255
    else if (b < 0) b = 0

    let g = (num & 0x0000ff) + amt

    if (g > 255) g = 255
    else if (g < 0) g = 0

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
  }

  public static getColorByIndex(index: number = 0): string {
    let color: string
    if (index <= ColorManager.colors.length) {
      color = ColorManager.colors[index]
    } else {
      let idx = index
      let amount = 0
      do {
        idx -= ColorManager.colors.length
        amount++
      } while (idx > ColorManager.colors.length)
      color = ColorManager.LightenDarkenColor(ColorManager.colors[idx - 1], amount * 25)
    }

    return color
  }
}
