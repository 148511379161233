/** @format */

import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import LiveEvent from '@/models/LiveEvent'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'live_event',
})
export default class LiveEventModule extends VuexModule {
  public data: LiveEvent[] = [];

  public options: SelectOption[] = [];

  public synchronized: boolean = false;

  public get api() {
    return new Api(false)
  }

  @Mutation
  public setSynchronized(status: boolean) {
    this.synchronized = status
  }

  @Mutation
  public update(live_events: LiveEvent | LiveEvent[]) {
    let data: Array<LiveEvent> = []
    if (live_events instanceof LiveEvent) {
      data.push(live_events)
    } else {
      data = live_events
    }

    let result = this.data

    data.forEach((live_event: LiveEvent) => {
      let found: boolean = false
      result = result.map((obj: LiveEvent) => {
        if (obj.id == live_event.id) {
          found = true
          return live_event
        }
        return obj
      })

      if (!found) {
        result.push(live_event)
      }
    })

    this.data = result
  }

  @Mutation
  public delete(live_events: LiveEvent | LiveEvent[]) {
    let data: Array<LiveEvent> = []
    if (live_events instanceof LiveEvent) {
      data.push(live_events)
    } else {
      data = live_events
    }

    let result = this.data

    data.forEach((live_event: LiveEvent) => {
      result = result.filter((obj: LiveEvent) => obj.id != live_event.id)
    })

    this.data = result
  }

  @Action
  public async find(id: string): Promise<LiveEvent | null> {
    return new Promise(resolve => {
      const o = this.data.find(live_event => live_event.id === id)

      if (o instanceof LiveEvent) {
        resolve(o)
      } else {
        return this.api
          .get(`live_event/${id}`)
          .then(response => {
            // Parse & cache data
            const data = LiveEvent.toObject(response.data.result.live_event)

            if (data instanceof LiveEvent) {
              resolve(data)
            }
          })
          .catch(() => {
            resolve(null)
          })
      }
    })
  }

  @Action
  public async paginate(options: PaginateOptions) {
    return this.api
      .get('live_events/paginate', options)
      .then(response => {
        // Parse & cache data
        const data = LiveEvent.toObjectList(response.data.result.live_events)

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  @Action
  public async searchOptions(query: any) {
    return this.api
      .get('live_event/search/option', query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }
}
