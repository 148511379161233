import moment from 'moment'
import { RRule } from 'rrule'
import Vue from 'vue'
import DatePickerDate from '../DatePickerDate'
import LiveEvent from '../LiveEvent'
import ColorManager from './ColorManager'
import ScheduleOverwrite from './ScheduleOverwrite'

export default class Schedule {
  public id: number = 0;

  public name: string = '';

  public start_date: string = moment().format('YYYY-MM-DD');

  public end_date: string = moment().format('YYYY-MM-DD');

  public start_time: string = moment()
    .set({ minute: 0, second: 0 })
    .format('HH:mm:ss');

  public end_time: string = moment()
    .add(1, 'hour')
    .set({ minute: 0, second: 0 })
    .format('HH:mm:ss');

  public interval: number = 1;

  public frequency: Array<any> = [];

  public league_id: string = '';

  public network_ids: string[] = [];

  public automated_mode: string = 'game_duration';

  public game_duration: number = 180;

  public game_head_start: number = 30;

  public overwrites: Array<ScheduleOverwrite> = [];

  public expire_at: string | null = null;

  public _color: string = '';

  public get color(): string {
    if (!this._color) {
      if (this.id <= ColorManager.colors.length) {
        this._color = ColorManager.colors[this.id - 1]
      } else {
        let idx = this.id
        let amount = 0
        do {
          idx -= ColorManager.colors.length
          amount++
        } while (idx > ColorManager.colors.length)
        this._color = ColorManager.LightenDarkenColor(
          ColorManager.colors[idx - 1],
          amount * 25,
        )
      }
    }

    return this._color
  }

  public _datepicker_time: any = new DatePickerDate(
    moment()
      .set({ minute: 0, second: 0 })
      .format('YYYY-MM-DD HH:mm:ss'),
    moment()
      .add(1, 'hour')
      .set({ minute: 0, second: 0 })
      .format('YYYY-MM-DD HH:mm:ss'),
  );

  public get datepicker_time(): DatePickerDate {
    return new DatePickerDate(this.start_time, this.end_time, null, 'HH:mm:ss')
  }

  public set datepicker_time(datepicker_time: DatePickerDate) {
    this.start_time = datepicker_time.start_time
    this.end_time = datepicker_time.end_time
  }

  public _type: string = 'weekly';

  public get type(): string {
    return this._type
  }

  public set type(type: string) {
    if (this.type !== type) this.frequency = []
    this._type = type
  }

  public get description() {
    if (this.type === 'once') {
      return 'Occurs only once.'
    } if (this.type === 'weekly') {
      return `Occurs every ${this.interval} ${
        this.interval === 1 ? 'week' : 'weeks'
      } on ${this.frequency.join(', ')}${
        this.expire_at && this.expire_at != 'Invalid date'
          ? `, until ${this.expire_at_human}`
          : ''
      }, starting at ${moment(this.start_date).format('MM/DD/YYYY')}.`
    } if (this.type === 'monthly') {
      return `Occurs every ${this.interval} ${
        this.interval === 1 ? 'month' : 'months'
      } on day${this.frequency.length > 1 ? 's' : ''} ${this.frequency.join(
        ', ',
      )}${
        this.expire_at && this.expire_at != 'Invalid date'
          ? `, until ${this.expire_at_human}`
          : ''
      }, starting at ${moment(this.start_date).format('MM/DD/YYYY')}.`
    } if (this.type === 'automated') {
      return `Automated schedule, based on ${this.league_id
        .replaceAll('_', ' ')
        .toUpperCase()} live events.`
    }
  }

  public get automated_events() {
    const events = LiveEvent.getAllByLeague(this.league_id, this.network_ids)

    if (this.overwrites.length > 0) {
      return events.filter(event => {
        const overwrite = this.overwrites.find(
          overwrite => overwrite.event === event.id,
        )

        return !overwrite || overwrite.type !== 'cancel'
      })
    }
    return events
  }

  public get start_at(): string {
    return `${this.start_date} ${this.start_time}`
  }

  public set start_at(start_at: string) {
    this.start_date = moment(start_at).format('YYYY-MM-DD')
    this.start_time = moment(start_at).format('HH:mm:ss')
  }

  public get end_at(): string {
    return `${this.end_date} ${this.end_time}`
  }

  public set end_at(end_at: string) {
    this.end_date = moment(end_at).format('YYYY-MM-DD')
    this.end_time = moment(end_at).format('HH:mm:ss')
  }

  public get start_at_human(): string {
    if (!['weekly', 'monthly'].includes(this.type)) {
      return moment(this.start_at).format('MM/DD/YY h:mm a')
    }
    return moment(this.start_time, 'HH:mm::ss').format('h:mm a')
  }

  public get end_at_human(): string {
    if (!['weekly', 'monthly'].includes(this.type)) {
      return moment(this.end_at).format('MM/DD/YY h:mm a')
    }
    return moment(this.end_time, 'HH:mm::ss').format('h:mm a')
  }

  public get expire_at_human(): string {
    if (this.expire_at) {
      return moment(this.expire_at).format('MM/DD/YYYY')
    }
    return 'Forever'
  }

  public get duration(): number {
    const start = moment(this.start_time, 'HH:mm::ss')
    const end = moment(this.end_time, 'HH:mm::ss')
    if (end.isBefore(start)) {
      return end.add(1, 'day').diff(start, 'minutes')
    }
    return end.diff(start, 'minutes')
  }

  public get rrule_frequency(): any {
    if (this.type === 'weekly') {
      return this.frequency.map(day => (
        moment()
          .isoWeekday(day)
          .isoWeekday() - 1
      ))
    } if (this.type === 'monthly') {
      return this.frequency.map(day => {
        if (isNaN(day)) {
          return RRule.FR.nth(Number(day.replace('nth', '')))
        }
        return Number(day)
      })
    }
    return null
  }

  public get rrule(): any {
    if (this.type === 'weekly') {
      return {
        freq: this.type,
        interval: this.interval,
        byweekday: this.rrule_frequency,
        dtstart: this.start_at,
        until: this.expire_at,
      }
    } if (this.type === 'monthly') {
      return {
        freq: this.type,
        interval: this.interval,
        bymonthday: this.rrule_frequency,
        dtstart: this.start_at,
        until: this.expire_at,
      }
    }
    return null
  }

  constructor(type: string, ...args: any) {
    this.type = type
    if (this.type === 'once') {
      /**
       * 0: Start At
       * 1: End At
       */
      if (args[0]) {
        // Set Start Date if provided
        this.start_at = args[0]
      }

      if (args[1]) {
        // Set End Date if provided
        this.end_at = args[1]
      }
    } else if (['weekly', 'monthly'].includes(this.type)) {
      /**
       * 0: Frequency
       * 1: Start Date
       * 2: Start Time
       * 3: End Time
       * 4: Interval
       * 5: Expires At
       */
      if (args[0]) {
        // Set Start Date if provided
        this.frequency = args[0]
      }

      if (args[1]) {
        // Set Start Time if provided
        this.start_date = args[1]
      }

      if (args[2]) {
        // Set Start Time if provided
        this.start_time = args[2]
      }

      if (args[3]) {
        // Set End Time if provided
        this.end_time = args[3]
      }

      if (args[4]) {
        // Set Interval if provided
        this.interval = args[4]
      }

      if (args[5]) {
        // Set Expires At if provided
        this.expire_at = args[5]
      }
    } else if (this.type === 'automated') {
      /**
       * 0: Automated ID
       */
      if (args[0]) {
        this.league_id = args[0]
      }

      /**
       * 1: Network IDs
       */
      if (args[1]) {
        this.network_ids = args[1]
      }

      /**
       * 2: Automated Mode
       */
      if (args[2]) {
        this.automated_mode = args[2]
      }

      /**
       * 3: Headstart
       */
      if (args[3]) {
        this.game_head_start = args[3]
      }

      /**
       * 4: Game duration
       */
      if (args[4]) {
        this.game_duration = args[4]
      }

      /**
       * 5: Overwrites
       */
      if (args[5]) {
        this.overwrites = ScheduleOverwrite.toObjectList(args[5])
      }
    }
  }

  public addOverwrite(overwrite: ScheduleOverwrite) {
    overwrite.id = this.overwrites.length + 1
    this.overwrites.push(overwrite)
  }

  public updateOverwrite(overwrite: ScheduleOverwrite) {
    Vue.set(
      this,
      'overwrites',
      this.overwrites.map(o => {
        if (o.id === overwrite.id) return overwrite
        return o
      }),
    )
  }

  public removeOverwrite(id: number) {
    let idx = 1
    this.overwrites = this.overwrites
      .filter(o => o.id !== id)
      .map(o => {
        o.id = idx++
        return o
      })
  }

  public buildRequest(): any {
    if (this.type === 'once') {
      return {
        id: this.id,
        name: this.name,
        type: this.type,
        start_at: this.start_at,
        end_at: this.end_at,
      }
    } if (['weekly', 'monthly'].includes(this.type)) {
      return {
        id: this.id,
        name: this.name,
        type: this.type,
        frequency: this.frequency,
        start_date: this.start_date,
        start_time: this.start_time,
        end_time: this.end_time,
        interval: this.interval,
        expire_at: this.expire_at === 'Invalid date' ? null : this.expire_at,
      }
    } if (this.type === 'automated') {
      const ret: any = {
        id: this.id,
        name: this.name,
        type: this.type,
        league_id: this.league_id,
        network_ids: this.network_ids,
        automated_mode: this.automated_mode,
        game_head_start: this.game_head_start,
        game_duration: this.game_duration,
        overwrites: this.overwrites.map(o => o.buildRequest()),
      }

      if (this.automated_mode === 'all_day') {
        delete ret.game_head_start
        delete ret.game_duration
      }

      return ret
    }
  }

  public static toObjectList(list: Array<Schedule>): Array<Schedule> {
    return list.map(s => Schedule.toObject(s))
  }

  public static toObject(s: Schedule): Schedule {
    let schedule = new Schedule(s.type)
    if (s.type === 'once') {
      schedule = new Schedule(s.type, s.start_at, s.end_at)
    } else if (s.type === 'weekly') {
      schedule = new Schedule(
        s.type,
        s.frequency,
        s.start_date,
        s.start_time,
        s.end_time,
        s.interval,
        s.expire_at,
      )
    } else if (s.type === 'monthly') {
      schedule = new Schedule(
        s.type,
        s.frequency,
        s.start_time,
        s.end_time,
        s.interval,
      )
    } else if (s.type === 'automated') {
      schedule = new Schedule(
        s.type,
        s.league_id,
        s.network_ids,
        s.automated_mode,
        s.game_head_start,
        s.game_duration,
        s.overwrites,
      )
    }

    schedule.id = s.id
    schedule.name = s.name

    return schedule
  }
}
