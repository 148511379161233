import moment from 'moment'
import LiveEvent from '../LiveEvent'

export default class ScheduleOverwrite {
  public id: number | null = null;

  public event: string = '';

  public type: string = 'cancel';

  public get name() {
    return LiveEvent.find(this.event)?.name
  }

  public get datetime() {
    const e = LiveEvent.find(this.event)

    if (e) return moment(e.created_at).format('MM/DD/YY h:mm a')

    return ''
  }

  public buildRequest(): any {
    return {
      id: this.id,
      event: this.event,
      type: this.type,
    }
  }

  public static toObject(
    overwrite: any,
    cache: boolean = true,
  ): ScheduleOverwrite {
    const obj = new ScheduleOverwrite()

    obj.id = overwrite.id
    obj.event = overwrite.event
    obj.type = overwrite.type

    return obj
  }

  public static toObjectList(
    overwrites: any[],
    cache: boolean = true,
  ): ScheduleOverwrite[] {
    const list = overwrites.map(s => ScheduleOverwrite.toObject(s, false))

    return list
  }
}
